/* 

---- TYPOGRAPHY SYSTEM

Font sizes (rem):
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Font weights:
Default: 400
Medium: 500
Semi-bold: 600
Bold: 700

- Line heights:
Default: 1
Small: 1.05
Paragraph: 1.6
    
----- COLOURS 

-Primary: 

-Tints: 
-Shades:
-Accents:
-Greys: 

----- BORDER-RADIUS

Default: 9px

----- WHITESPACE

Spacing System (px):
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

*/

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hidden {
  display: none;
}

/****************************/
/* MENU */
/****************************/

.social-link {
  width: 4.4rem;
  height: 4.4rem;
  text-decoration: none;
  border: none;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
}

.social-link:hover {
  transform: scale(1.2);
}

.social-link-icon {
  width: 2rem;
}

.links-container {
  display: flex;
  justify-content: center;
  margin-bottom: 2.8rem;
  gap: 1rem;
}

/****************************/
/* HERO PAGE */
/****************************/

.background-container {
  height: 100vh;
  position: relative;
}

.coloured-box {
  position: absolute;
  top: 55%;
  height: 1.7rem;
  background-color: var(--primary-colour-dark);
  z-index: -1;
}

.text-with-box {
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
}

.coloured-box-project {
  width: 30.7rem;
}

.profile-picture {
  border-radius: 50%;
  width: 50%;
  height: auto;
  object-fit: cover;
  object-position: center;
  margin-bottom: 1rem;
  margin-top: -12rem;
}

.row-mb {
  margin-bottom: 5rem;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.intro-text {
  font-size: 4.4rem;
  font-weight: 600;
  line-height: 1.2;
}

.body-text {
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.5;
}

.main-paragraph {
  margin-bottom: 6rem;
}

.scroll-down-btn {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: none;
  background-color: none;
  transition: all 0.2s ease-in-out;
}

.scroll-down-btn:hover {
  transform: scale(1.2);
}

.animated-text {
  font-size: 4rem;
  font-weight: 400;
  line-height: 1.2;
}
